import { useStore } from "@ludens-reklame/rubics-v3-react/store/hooks";
import { File, Image } from "@ludens-reklame/rubics-v3-sdk/file/types";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { RichText } from "../../internal-components/richText/RichText.js";

interface Props {
  image?: Image;
  video?: File;
  bigImage?: boolean;
  border?: boolean;
  autoFetch?: boolean;
  text?: string;
}

const imageVideo: React.FC<Props> = ({
  image,
  video,
  bigImage,
  autoFetch,
  border,
  text,
}) => {
  const context = useStore().context;
  const page = context.page;
  const product = context.product;
  const autoImage = product?.primaryImage || page?.image;
  const usedImage = autoFetch ? autoImage : image;

  if (!usedImage && !video) {
    return null;
  }

  return (
    <div
      className={`image-video ${bigImage ? "mw" : "mw-small"} ${
        border ? "border" : ""
      } vs-xl hs`}
    >
      <div className="container">
        {usedImage && (
          <img
            src={transformImageUrl(usedImage, { width: 2000 })}
            alt={usedImage.altText}
          />
        )}
        {video && (
          <video
            controls
            poster={
              video.previewImage
                ? transformImageUrl(video.previewImage, { width: 1080 })
                : undefined
            }
          >
            <source src={video.url} type={video.mimeType} />
            Din nettleser støtter ikke denne video taggen.
          </video>
        )}
        {text && (
          <div className="text">
            <RichText html={text} />
          </div>
        )}
      </div>
    </div>
  );
};

export default imageVideo;
